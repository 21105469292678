:root{
    --background-color: #343A40;
}
.navContainer{
    width:167%;
}
nav {
    z-index: 2;
   width: 77.4%;

   .deskNavWrap{
    display: flex;
    flex-direction: row;
    flex: left;
    justify-content: space-between;
    padding-right: 0;
   }
}

.navagation{
    height: 10vh;
    box-shadow: 0 0 0 2px;
}
.color{
  
}
.nIconList{
    margin-right: 0%;
    width: 20vw;
    padding-right: 0;
    height: 5vh;
    display: flex;
    flex-direction: row;
    flex: left;
    justify-content: space-between;
    height: 100%;

   
    .nIcon{
        height: 50px;
        width: 50px;
    
    }
}
@media only screen and (max-width: 1180px) and (min-width: 971px){
    .navContainer{
    width: 167%;
    }
    .calendly-badge-widget, .calendly-badge-content{
        position: absolute;
        top: 0;
        margin-left: 45%;
        margin-top: -1%; 
        width: 25vw !important;
  
        height: 7%;
      }
   
}
@media only screen and (max-width: 970px) and (min-width: 768px){
    .calendly-badge-widget, .calendly-badge-content{
      bottom: 40%;
       border: none;
    }
    @media only screen and (max-width: 900px) and (min-width: 768px) {
        .calendly-badge-widget, .calendly-badge-content{
            bottom: 0%;
             border: none;
          } 
    }
    nav{
        
        height: auto;
        .navagation{
            width: 78% !important;
            height: 8vh;
                .deskNavWrap{
                    align-content: center;
                #collasible-nav-dropdown, .navbar-brand{
                    margin-top: 1vh;
                }
            button{
                position: absolute;
                 margin-top: 1vh;
                    margin-left: 0 !important;
                    right: 0;
                }
            
            }
        }
    }

}
@media only screen and (max-width: 767px) and (min-width: 574px){
    nav{
        z-index: 5;
        margin: 0vh !important;
        width: 60% !important;
        
        .deskNavWrap{
            display: none !important;
        }
       .nIconList {
            position: absolute;
            margin-top:  5vh !important;
            margin-left: -8vw;
            height: 25vh !important;
            width: 8vw;
            display: flex;
            flex-direction: column;
            background-color: var(--background-color);
            border-radius: 15px;
            flex: left;
        }
    }
    
   .sNavagation{
    padding: 0;
    box-shadow: 0px 4px 0px 0px rgba(0,0,0,.25);
    height: 5% !important;

        #smallNav{
            display: show !important;
            padding: 0;

            .navbar-brand{
                margin-left: 50% !important;

                #collasible-nav-dropdown{
                   margin: 0 0 0 150% !important;
                    align-items: center !important;
                    font-size: 22px;
                    padding: 0;
                }
                div.dropdown-menu.show{
                    box-shadow: 0 0 4px 0 rgba(0,0,0,.25);
                    background-color: var(--background-color);
                    width: 100vw;
                    margin-left: 0vw !important;
                    padding: 0;

                    a{
                        font-size: 22px;
                        padding:  2% ;
                        text-align: center;
                    }
                    a:nth-child(5){
                        border-top: solid thin black;
                        border-bottom: solid thin black;
                    }
                    a:nth-child(10){
                        border-bottom: solid thin black;
                    }
                    .iconL{
                      
                        display: flex;
                        flex: left;
                        flex-direction: row;
                        justify-content: space-between;
                        width: 108% !important; 
                        margin-left: -4%;
                        margin-bottom: -4%;
                        border-bottom: solid 2px black;
                        .github-icon{
                            height: 25px;
                            width: 75px;
                        }
                        .catopuss-icon{
                            height: 45px;
                            width: 45px;
                            margin-top: 6px;
                            margin-left: 20%;
                        }
                        .browser-icon{
                            height: 50px;
                            width: 50px;
                           
                        }
                        .linkedin-icon{
                            height: 55px;
                            width: 55px;
                        }
                    }
                }
                height: 5% !important;
                display: show !important;
                margin: 0 !important;
            }
        }
    }
}
@media only screen and (max-width: 573px) and (min-width: 416px){
    nav{
        width: 66%;
        height: 5vh !important;
        margin-left: -2% !important;
       
       .dropdown-toggle, .nav-link {
        margin-left:75%;
        width: 100%;
       }  
    }
    
    div.dropdown-menu.show{
        box-shadow: 0 0 4px 0 rgba(0,0,0,.25);
        background-color: var(--background-color);
        width: 100vw;
        margin-left: 0vw !important;
        padding: 0;

        a{
            font-size: 22px;
            padding:  2% ;
            text-align: center;
        }
        a:nth-child(5){
            border-top: solid thin black;
            border-bottom: solid thin black;
        }
        a:nth-child(10){
            border-bottom: solid thin black;
        }
        .iconL{
          
            display: flex;
            flex: left;
            flex-direction: row;
            justify-content: space-between;
            width: 108% !important; 
            margin-left: -4%;
            margin-bottom: -4%;
            border-bottom: solid 2px black;
            .github-icon{
                height: 25px;
                width: 75px;
            }
            .catopuss-icon{
                height: 45px;
                width: 45px;
                margin-top: 6px;
                margin-left: 20%;
            }
            .browser-icon{
                height: 50px;
                width: 50px;
               
            }
            .linkedin-icon{
                height: 55px;
                width: 55px;
            }
        }
    }
}
@media only screen and (max-width: 415px) and (min-width: 280px){
.navContainer{
height: 5%;
box-shadow: 0 0 4px 0 rgba(0,0,0,.25);
}
    nav{
    height: 10%;
    
   }
    #smallNav{
        margin-right: 2% !important;
        height: 10%;
        
    }
    .dropdown-toggle, .nav-link {
        margin-right: -40% !important;
        text-align: center;
        width: auto;
       
    }
    div.dropdown-menu.show{
        margin-left: -5% !important;
    }
  }