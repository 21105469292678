

.rotom{
    width: 600px;
    height: 400px;
    border-radius: 45px;

}

.cssart{
  z-index: 4;
  position: absolute;
    margin: auto;
    padding-top: 20px;
    margin-left: 0px;
    .pokeball{
      margin-left: 55vw;
      margin-top: -30%;
        #pokeball{
          top: -50px; bottom: 0; left: 0; right: 0;
          margin: auto;
          border-radius: 50%;
          background: radial-gradient(circle at 100px 100px, #C22A00, #000);
          height: 450px;
          width: 450px;  
          float: right;
          
      }
    }
    
    
}
.tracker{
  z-index: 4;
    display: flex;
    flex-direction: row;
    flex: left;
    width: 1299px;
    height: 477px;
    justify-content: space-around;
    margin: auto;
    padding-top: 17px;
    padding-bottom: 7px;
    background-color: #EFFBFF;
}
.rotom-blurb{
  margin-top: 30px;
  width: 400px;
   margin-left: -200px;
   font-family: 'Inconsolata', monospace;
   font-size: 20px;
   letter-spacing: 1px;
   line-height: 1.6;
}

.rotom-pic{
    height: 100px;
    width: 200px;
}

#layer1{
  position: absolute;
  z-index: 1;
  height: 230px;
  width: 450px;
    background-color: #3498db;
    border-top-left-radius: 450px;
    border-top-right-radius: 450px;
    border-bottom: none;
    background: radial-gradient(circle at 100px 100px, #ee1515, #000);
}

#bottom-half{
    position: absolute;
    margin: auto;
    z-index: 1;
    height: 230px;
    width: 450px;
    border-radius: 0 0 450px 450px;
    background: radial-gradient(circle at 100px 100px, white, #000);
    margin-top: 220px;
    border-top: 20px black solid;
}
#center{
  position: absolute;
    z-index: 3;
    margin-top: 185px;
    margin-left: 170px;
    border-radius: 50%;
    height: 95px;
    width: 95px;
    background-color:whitesmoke;
    -webkit-animation-name: color;
            animation-name: color;
    -webkit-animation-duration: 4s;
            animation-duration: 4s;
    -webkit-animation-iteration-count: infinite;
            animation-iteration-count: infinite;
    border: 13px solid black;
    position: absolute;
    
}
.bblurb{
  margin-top: 50vh;
  margin-left: 3vw;
  
}
.blurb{
    width: 400px;
    text-align:  left;
    letter-spacing: 1px;
    line-height: 1.6;
    font-size: 20px;
    font-family: 'Inconsolata', monospace;
}
@-webkit-keyframes color {
    0% {
      background-color: #222;
    }
    50% {
      background-color: whitesmoke;
    }
    100% {
      background-color: #222;
    }
}
@keyframes color {
    0% {
      background-color: #222;
    }
    50% {
      background-color: whitesmoke;
    }
    100% {
      background-color: #222;
    }
}

/* Below is css for Rotom*/
.rotom_full{
    transform: scale(0.5);
    position: relative;
    margin-left: -270px;
  }
.dexneck {
    width: 60px;
    height: 100px;
    z-index: 1;
    background: #d44338;
    border-left: 1px solid #000;
    border-right: 1px solid #000;
    position: absolute;
    margin-left: 450px;
    margin-top: -90px;
  }
  .dexneck-right {
    width: 70px;
    height: 60px;
    background: #d44338;
    border-radius: 5px 60px 0px 0px;
    border-left: 1px solid #000;
    border-top: 1px solid #000;
    position: absolute;
    margin-top: -150px;
    margin-left: 450px;
  }
  .dexneck-tip {
    width: 0;
    height: 0;
    border-right: 40px solid #d44338;
    border-top: 75px solid transparent;
    border-left: 45px solid transparent;
    border-bottom: 30px solid #d44338;
    border-radius:  5px 0px 5px 5px;
    position: absolute;
    z-index: 1;
    margin-top: -200px;
    margin-left: 450px;
  }
.dexbody {
    width: 1000px;
    height: 450px;
    border: 1px solid #000;
    background: #d44338;
    border-radius: 20px;
  }
.rotom_head{
    position: absolute;
    height:350px;
    width:350px;
    border-radius: 300px;
    background: transparent;
    border: 3px solid black;
    margin-left: 31%;
    top:11%;
}
.left_eye{
    z-index: 1;
      position: absolute;
      height:45px;
      width:200px;
      border-radius: 90px / 45px;
      background: #EDECEF;
      transform: rotate(70deg);
      margin-top: 90px;
      margin-left: -10px;
  }
  .left_slit{
    position: absolute;
    border-top: 3px solid black;
    width: 47px;
    transform: rotate(75deg);
    margin-left: 80px;
    margin-top: 20px;
}
.left_eyeris{
    position: absolute;
    height:25px;
    width:140px;
    border-radius: 90px / 45px;
    background: rgb(126,194,218);
    background: linear-gradient(70deg, rgba(126,194,218,1) 0%, rgba(145,218,238,1) 50%, rgba(177,242,248,1) 100%);
    transform: rotate(180deg);
    margin-left: 30px;
    margin-top: 10px;
  }
.right_eye{
    position: absolute;
    height:45px;
    width:180px;
    border-radius: 90px / 45px;
    background: #EDECEF;
    transform: rotate(-70deg);
    margin-left: 180px;
    margin-top:90px;
}
.right__slit{
    position: absolute;
    border-top: 3px solid black;
    width: 47px;
    transform: rotate(-75deg);
    margin-left: 50px;
    margin-top:20px;
}
.right_eyeris{
    position: absolute;
    height:25px;
    width:140px;
    border-radius: 90px / 45px;
    background: rgb(126,194,218);
    background: linear-gradient(70deg, rgba(126,194,218,1) 0%, rgba(145,218,238,1) 50%, rgba(177,242,248,1) 100%);
    transform: rotate(0deg);
    margin-left: 20px;
    margin-top:10px;
  }
  .rotom_mouth{
    position: absolute;
    display: flex;
    flex-direction: row;
    flex: right;
    justify-content: center;
    width: 200px;
    margin-left: 87px;
    margin-top: 250px;
  }
  .rotom_mouth_left{
    position: relative;
    width:50px; 
    height:35px; 
    border:1px solid #000; 
    background: white;
    border-radius: 0 0 0 90px;
  }
  .rotom_mouth_center{
    position: relative;
    width:25px; 
       height:35px; 
       border:1px solid #000; 
       background:white;
       padding-left: 20px;
  }
  .rotom_mouth_right{
    position: relative;
    width:50px; 
    height:35px; 
    border:1px solid #000; 
    background: white;
    border-radius: 0 0 90px 0;
  }
.dextail {
    z-index: 1;
    width: 0;
    height: 0;
    border-top: 100px solid #d44338;
    border-left: 50px solid transparent;
    border-right: 50px solid transparent;
    position: relative;
    margin-left:450px;
  }
  @media only screen and (max-width: 1180px)  {

     #pokeball{

      margin-left: -25vw;
      margin-top: -17vh;

    } 
  .bblurb{
    margin-top: 40% !important;
    z-index: 5;
  }
  }
  @media only screen and (max-width: 970px)  {
    .cssart {
      bottom: 0%;
      left: 5vw;
      height: 65vh;
      width: 90vw;
      .pokeball{
        margin-left: 40vw !important;
        margin-top: -1% !important;
        transform: scale(.8);
      }
  
      .blurb {
        margin: 0px;
        width: 33%;
        margin-left: -0vh;
        margin-top: 10% !important;
      }
    }
 
 
 }

  @media only screen 
and (min-device-width: 375px) 
and (max-device-width: 667px) 
and (-webkit-min-device-pixel-ratio: 2) { 
  .cssart{
    width: 550px;
    height: 935px;
    flex-wrap: wrap;
  }
  .official-ball{
    margin-left: 95px;
  }
  .tracker{
    flex-wrap: wrap;
    width: 550px;
    height: 847px;
   
  }
  .rotom_full{
    margin-left: 5px;
  }
  .rotom-blurb{
    margin-top: -80px;
    margin-left: 10px;
    width: 500px;
  }
}
