:root {
  --font-color: #05ebe2;
  --font-accent: #06d493;
  --font-style: "'Lucida Console', monospace";
}

p {
  color: #05ebe2;
}
form{
  display: flex;
  flex: left;
  flex-direction: column;
  border-radius: 33px;
 
}
.componitContainer {
  margin: 0;
  padding: 0;
  font-weight: normal;
  display: block;
  font: inherit;
}

.project {
  position: relative;
  z-index: 1;
  margin-left: 43vw;
  margin-top:-14%;
  margin-right: 1vw;
  color: #06d493;
  background-color: #343a40;
  border-radius: 35px;
  height: auto;
  border: solid 2px black;
  box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.25);
  .screenshot {
    position: relative !important;
    margin-left: 20%;
    padding-left: 5px;
    padding-top: 5px;
    width: 350px !important;
    height: 192px !important;
    border-radius: 30px;
  }
  .detailContainer {
    position: relative;
    margin-left: 5;
    text-align: left;
    padding-left: 20px;
    font-family: "Lucida Console", monospace;

    .iconL {
      display: flex;
      flex: left;
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
      width: 100% !important;
      margin-bottom: 1vh !important;
    }
  }
}

.github-icon {
  height: 25px;
  width: 75px;
}
.catopuss-icon {
  height: 45px;
  width: 45px;
  margin-top: 6px;
}
.browser-icon {
  height: 50px;
  width: 50px;
}
.myClassname, .res-contain {
  z-index: 3;
  width: 40vw;
  height: 78vh;
  border-radius: 45px;
  margin-top: -10%;
  margin-left: 35%;
}


@media only screen and (max-width: 1180px) {
  .componitContainer {
    margin-top: 0%;
    position: relative;

    width: 100%;
    height: 100%;

  }

  .about {
    position: relative;
    width: 45%;
    height: auto;
    margin-left: 2vw !important;
    margin-top: 2% !important;
    .me {
      height: 17vh ;
      width: 13vw; 
    }
    .me::after{
      margin-top: 2vh;
    }
    .blurb-a {
      margin-top: 2vh;
      margin-left: 14vw;
      width: 65%;
      font-size: small;
    }
    .expandable-container {
      .expandable-header {
        color: var(--font-color);
        font-style: var(--font-style);
      }
    }
    box-shadow: 4px 4px 4px 4px rgba(0, 0, 0, 0.25);
  }

  .project {
    position: relative;
    width: 55% !important;
    height: 80%;
    top: -9%;
    right: 00%;
    border: solid 2px black;
    .screenshot {
      position: relative;
      z-index: index 1;
      left: 0%;
      width: 40% !important;
      margin-bottom: -3em;
      margin-left: -1%;
      padding: 1% 2% 0 0;
      height: auto;
      border-radius: 35px;
    }

    .detailContainer {
      position: absolute;
      width: auto !important;
      height: auto;
      margin-top: 0%;

      .cardHeader {
        position: relative;

        width: 60%;
        margin-top: -23%;
        right: -40% !important;
        height: auto;
        .hTittle {
          font-size: 27px;
        }
        .hTittle2 {
          font-size: 17px !important;
        }
      }
      #htDescription {
        margin-top: 3%;
      }
      .ipad-description {
        width: 100%;
        height: auto;
        font-size: medium;
        margin-top: 15%;
      }
      #htLogo {
        margin-top: -2%;
        padding: 0% 4% 0 4%;
      }
    }
  }
  .pokeball {
    margin-right: -30%;
    margin-top: 30%;
  }

}

@media only screen and (max-width: 970px) and (min-width: 901px) {
  .componitContainer {
    position: absolute;
    flex-direction: column;
    flex: left;
    display: flex;
    margin-left: 0%;
    width: 97%;
  }
  .about {
    position: relative;
    width: 100% !important;
    height: auto !important;
    margin-left: 2%vw !important;

    padding-top: 1vh;
    .me {
      margin-top: 2%;
      margin-left: 2%;

      width: 18vh !important;
      height: 75%;
      border-radius: 27px;
    }
    .blurb-a {
      position: relative;
      width: 95% !important;
      height: 95% !important;
      font-size: 20px !important;
      margin-top: 2vh !important;
      margin-right: 40vw !important;
    }
  }

  .project {
    position: relative;
    height: auto;
    margin-top: 10%;
    left: -43%;
    width: 100% !important;

    .screenshot {
      position: relative;
      margin-top: 1vh;
      width: 25vw !important;
      height: 20vh !important;
      margin-left: -0vw;
    }

    .detailContainer {
      position: relative;
      width: 100% !important;
      height: 100%;
      margin-left: 2vw;
      top: -0%;
      border: none !important;

      padding: 0;
      
      .cardHeader {
        position: relative;
        margin-left: 5%;
        left: 20%;
        margin-top: -10%;
        margin-right: 3%;
        width: 69%;
        h5 {
          font-size: 15px !important;
        }
      }
      #mSummery {
        margin-top: 9% !important;
      }
      .ipad-description {
        margin-top: 8%;
        margin-bottom: 0%;
        width: 94%;
     
        padding-bottom: 0;
      }

      .iconL {
        display: flex;
        flex-direction: row;
        flex: left;
        justify-content: space-between;
        align-items: center;
        height: auto;
        width: 94% !important;
        margin-left: -0vw;
        margin-top: -0vh;

      }
    }
  }

  .res-contain {
    Iframe {
      transform: none;
      top: 50%;
      width: 120%;
      height: 80%;
    }
  }
}

@media only screen and (max-width: 900px) and (min-width: 768px) {
  .componitContainer {
    position: relative;
  }
  .about {
    position: relative;
    min-height: 23.5% !important;
    width: 95% !important;
    margin-top: 1vh;
    margin-bottom: -1vh !important;
    padding-bottom: 0vh;
    padding-top: 0vh;

    margin-left: 5vw;
    width: 100%;
    height: auto !important;
    border: solid 2px black;
    .me {
      position: relative;
      margin: auto;
      height: 23vh;
      width: 19vw !important;

      margin-left: 0vw !important;
    }
    .blurb-a {
      position: relative;
      margin-top: 2vh !important;
      margin-left: 23vw !important;
      font-size: 20px !important;
      line-height: 1.3;
      width: 75% !important;
      height: auto;
      color: var(--font-color);
      padding-bottom: 0;
    }
    .expandable-container {
      display: show;

      font-style: var(--font-style);
      color: (var(--font-accent));
      font-weight: 400;
      font-size: 15px;
      line-height: 1;
      margin-left: 20vw;

      .blurb-a {
        border-top: solid thin black;
        position: relative;
        margin-top: 2vh !important;
        margin-left: 23vw !important;
        font-size: 19px !important;
        line-height: 1.3;
        width: 50%;
        height: auto;
        color: var(--font-color);
      }
    }
  }
  .cssart {
    .pokeball {
      margin-top: 24%;
      margin-left: 77%;
    }
    .blurb {
      margin-left: -1vw;
      margin-top: -7vh !important;
      width: 35% !important;
    }
  }

  .project {
    position: relative;
    left: -41%;
    margin-top: 10%;
    height: auto;
    width: 96% !important;
    border: solid 2px black;
    padding-bottom: 5% !important;
    .screenshot {
      position: relative;
      width: 25vw !important;
      height: 18vh !important;
      left: 10% !important;
      margin-top: 1vh;
      margin-left: -11%;
    }

    .detailContainer {
      position: relative;

      height: auto;
      margin-top: -0% !important;
      margin-bottom: -3% !important;
      margin-left: 0.5%;
      line-height: normal;

      .cardHeader {
        margin-top: -9%;
        margin-left: -15%;
        width: 72% !important;
        position: relative;
        width: 85%;
        padding-bottom: 0;
        .hTittle2 {
          margin-top: -1.5% !important;
        }
        h4 {
          margin-top: -1.5% !important;
          font-size: 30px;
        }
        h5 {
          margin-top: -1.5% !important;
          font-size: 17px !important;
        }
      }

      .ipad-description {
        margin-top: 3% !important;
        padding-left: 0;
        padding-right: 2vh;
        width: 100%;
        line-height: 1.5;
        margin-left: -1.5%;
        margin-right: 3vw;
        font-size: 17px;
      }
      .iconL {
        margin-top: -2%;

        padding-bottom: 0;
        margin-left: -1.5%;
        width: 95.5% !important;
      }
    }
  }
.myClassname, .res-contain{
  height: 80%;
  width: 75%;
  margin-left: 25%;
  margin-bottom: -103%;
}
}
@media only screen and (max-width: 767px) and (min-width: 574px) {
  .about {
    margin-left: -0% !important;
  }
  .me{
   
  }
  .project {
    left: 0;
    top: 18%;
    height: auto;
    margin-left: -0%;
    margin-bottom: -50 !important;
    padding-bottom: -0% !important;
    .detailContainer {
      position: relative;
    
      padding-bottom: 0 !important;
      .cardHeader {
        margin-left: -2%;
        margin-top: -20%;
      }
    }
  }
  .sAncor{
    z-index: 3;
    margin-left:45%;
    padding: 0;
    width: 55%;
    text-align: center;
    font-weight: 700;
   

    .resumeScreenshot{
      width: 100%;
   
    }
  }
}
@media only screen and (max-width: 573px) and (min-width: 414px) {
  .about {
    .me {
      margin-top: 0;
      height: auto;
      width: 20%;
    }
  }

  .project {
    position: relative;
    margin-left: -0% !important;
    top: 10%;

    .detailContainer {
      #htHeader {
        margin-top: -27%;
      }
    }
  }
  .sAncor{
    z-index: 3;
   font-weight: 800;
    margin-left: 0%;
    height: 70%;
  
  
    .resumeScreenshot{
      height: 100%;
    }
  }
}
@media only screen and (max-width: 415px) and (min-width: 391px) {
  .about {
    position: relative !important;
    width: 100vw;
    height: auto !important;
    padding: 0 !important;
    margin-left: 0 !important;
    margin-top: 10px !important;
    border: solid thin black !important;
    .me {
      padding: 0 1% !important;
      height: 70px !important;
      width: 80px !important;
      margin: 0 auto;
    }
    .blurb-a {
      position: relative;
      padding: 0% !important;
      width: 95% !important;
      height: 96%;
      margin-left: 5% !important;
      margin-right: 10% !important;
      margin-top: 0% !important;
      border-radius: 20px;
      line-height: 1.4;
      font-size: 12px !important;
    }
    .expandable-container {
      position: relative;
      .expandable-header {
        color: var(--font-color);
        font-style: var(--font-style);
        width: 80%;
        margin-left: 30px;
        margin-left: 10%;
      }
    
    }
  }

  .sAncor {
    z-index: 2;
    text-align: center;
    position: absolute;
    width: 80%;
    height: auto;
    margin-bottom: -25% !important;
    font-size: 25px;
    font-weight: 700;
    bottom: 31%;
    margin-left: 10%;
    .resumeScreenshot {
      z-index: 2;
      width: 100%;
      height: 0%;
  
    }
  }

  .project{
    position: relative;
    display: block;
    position: relative;
    height: auto;
    padding-bottom: 10%;
    margin-bottom: 13% !important;
    margin-top: 22%;
    bottom: 0% !important;

    margin-left: 0;
    width: 100%;
    border: solid thin black !important;

    .detailContainer {
      z-index: 2;
      position: relative;
      margin: -5% 0 0 2% !important;
      padding: 0;
      top: 7%;
      height: 100%;
      width: 96% !important;
      border: none;
      margin: 0 0 0 -33%;

      .screenshot {
        z-index: 3;
        position: relative;
        width: 30% !important;
        height: 35% !important;

        margin: 0% 0 0 0%;
        padding: 2% 0 0 2%;
        border-top-left-radius: 35px;
      }
      #htHeader {
        margin-top: -39%;
        padding-bottom: 10%;
        border: none !important;
      }
      .cardHeader {
        margin: -21% 0 0 -10% !important;
        padding: 0 0 0 2%;

        height: auto !important;
        width: 72%;

        .hTitle {
          line-height: 0.9;
          font-size: 18px;
          padding-bottom: 1%;
        }
        .hTittle2 {
          padding: 0 !important;
          margin-top: -0%;
          font-size: 13px !important;
        }
        h5 {
          padding: 0 !important;
          margin-top: -4%;
          font-size: 13px;
          margin-bottom: 1%;
        }
        .hTittle3 {
          margin-top: -8%;
        }
      }
      .ipad-description {
        position: relative;
        padding: 0 0 0% 0;

        margin-top: 2%;
        margin-bottom: 4%;
        width: 100% !important;
        font-size: 17px !important;
        height: auto;
        line-height: 1;
        margin-left: 0%;
      }
      #htDescription {
        margin-bottom: 16%;
      }
      #htLogo {
        margin-top: -15%;
        padding: 0% 4% -10% 4%;
      }
      .iconL {
        position: relative !important;

        margin: 0% 0 0 2%;
        padding: 0 4% 4% 4%;

        height: 10% !important;
        width: 96% !important;
        .github-icon {
          margin-top: 20%;
        }
      }
    }
  }
  .pokeball{
    position: absolute;
 
  }
  #pokeball {
    position: absolute;
    margin-right: 20%;

    transform: scale(0.8);

  }
  .cssart {

    bottom: 0;
    width: 85vw !important;
    height: 50vh !important;
    margin: 0 0 10vh 0 !important;
 
    .pokeball{
   
    }
    .bblurb {
      font-size: 17px !important;
      width: 50% !important;
      margin-bottom: -40% !important;
    }
  }
 

#htProject{
  padding-bottom: 20%;

    #htHeader{
      margin: -35% 0 0 -10% !important;
     
    }
}

}

@media only screen and (max-width: 390px) and (min-width: 390px){
  .about {
    position: relative !important;
   
    width: 0% !important;
    height: auto !important;
    padding: 0 !important;
    margin-left: 0 !important;
    margin-top: 10px !important;
    border: solid thin black !important;
    .me {
      padding: 0 1% !important;
      height: 70px !important;
      width: 80px !important;
      margin: 0 auto;
    }
    .blurb-a {
      position: relative;
      padding: 0% !important;
      width: 95% !important;
      height: 96%;
      margin-left: 5% !important;
      margin-right: 10% !important;
      margin-top: 0% !important;
      border-radius: 20px;
      line-height: 1.4;
      font-size: 12px !important;
    }
    .expandable-container {
      position: relative;
      .expandable-header {
        color: var(--font-color);
        font-style: var(--font-style);
        width: 80%;
        margin-left: 30px;
        margin-left: 10%;
      }
      .expandable-header::target-text {
      }
    }
  }

  .sAncor {
    text-align: center;
    position: absolute;
    width: 96%;
    height: auto;
    margin-bottom: -50% !important;
    font-size: 25px;

    bottom: 24%;
    margin-left: 2%;
    .resumeScreenshot {
      width: 100%;
      height: 0%;
      border: solid thin red;
    }
  }
  #htProject {
    padding-bottom: 15%;
  }
  .project {
    display: block;
    position: relative;
    height: auto;
    top: 10%;
    margin-left: -0%;
    margin-bottom: 13% !important;
    bottom: 0% !important;

    width: 100%;
    border: solid thin black !important;

    .detailContainer {
      z-index: 2;
      position: relative;
      margin: -6% 0 0 1% !important;
      padding: 0 0 10% 0;
      top: 7%;
      height: 100%;
      width: 98% !important;

      margin: 0 0 0 -33%;

      .screenshot {
        z-index: 3;
        position: relative;
        width: 30% !important;
        height: 35% !important;

        margin: 0 0 0 0 !important;
        border: solid thin red;
        padding: 2% 0 0 2%;
        border-top-left-radius: 35px;
      }
      #htHeader {
        margin-top: -37%;
        padding-bottom: 0%;
      }
      .cardHeader {
        margin: -24.5% 0 0 -10%;
        padding: 0 0 0 2%;

        height: auto !important;
        width: 72%;

        .hTitle {
          line-height: 0.9;
          font-size: 18px;
          padding-bottom: 1%;
        }
        .hTittle2 {
          padding: 0 !important;
          margin-top: -0%;
          font-size: 13px !important;
        }
        h5 {
          padding: 0 !important;
          margin-top: -4%;
          font-size: 13px;
          margin-bottom: 1%;
        }
        .hTittle3 {
          margin-top: -8%;
        }
      }
      .ipad-description {
        position: relative;
        padding: 0 0 0% 0;

        margin-top: -1% !important;
        margin-bottom: 4%;
        width: 100% !important;
        font-size: 17px !important;
        height: auto;
        line-height: 1;
        margin-left: 0%;
      }
      #hTitle {
        margin-top: 5%;
        font-size: 22px;
      }
      #htDescription {
        margin-top: 8% !important;
        margin-bottom: 17%;
      }
      #htLogo {
        margin-top: -15%;
        padding: 0% 4% -10% 4%;
      }
      .iconL {
        position: relative !important;

        margin: -3% 0 0 2%;
        padding: 0 4% 4% 4%;

        height: 15% !important;
        width: 96% !important;
        .github-icon {
          margin-top: 20%;
        }
      }
    }
  }

  .cssart {
    bottom: 0;
    #pokeball {
      position: absolute;
      margin-left: -35%;
      margin-top: -86%;
      transform: scale(0.5);
    }
    .bblurb {
      font-size: 17px !important;
      width: 105% !important;
    }
  }
  .res-contain {
    height: 60%;
    width: auto;
    right: 0;

    bottom: 0;
  }

  #myId {
    height: 96%;
    width: 91%;
    margin-left: -6%;
    border: solid thin red;
  }
}

@media only screen and (max-width: 389px) and (min-width: 280px) {
  .me{
    height: 70px !important;
    width: 80px !important;
  }

  .sAncor{
    z-index: 3;
   font-weight: 800;
    margin-left: 0%;
    height: 55%;
  
  
    .resumeScreenshot{
      height: 100%;
    }
  }
}
