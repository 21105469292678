.moniter-screen{
   
    position: relative;
    z-index: 2;
     width:1300px; 
     height:530px; 
     border:5px solid black;
     border-bottom: 30px solid black;
     background: #FFF;
     margin: auto;
     margin-top: 20px;
     box-shadow: -4px 4px 4px 4px rgba(0,0,0,.25);
}
.white-gif{
    z-index: 3;
    width: 1290px;
    height: 490px;
}
.red-dot{
    position: relative;
    margin-top: -20px;
    z-index: 4;
    height:20px;
    width:20px;
    border-radius: 20px;
    background:red;
    background: radial-gradient(circle at 10px 10px, red, #000);
    float: right;
}
.red-dot{
    animation: blinker 1s linear infinite;
}
      
@keyframes blinker {
    50% {
          opacity: 0;
    }
}
.neck{
    position: relative;
    z-index: 2;
    margin: auto;
    width: 50px;
    height: 100px;
    border-radius: 50px/0px;
    background-color: black;
    box-shadow: 0px 4px 4px rgba(0,0,0,.25);
    
}.stand{
    z-index: 1;
    height:130px;
     width:130px;
     border-radius: 130px;
     background:black;
     margin: auto;
     margin-top: -20px;
}

@media only screen 
and (min-device-width: 375px) 
and (max-device-width: 667px) 
and (-webkit-min-device-pixel-ratio: 2) { 
    .moniter-screen{
        width: 550px;
        height: 953px;
        top: 190px;
        left: 10px;
    }
    .white-gif{
        z-index: 3;
        width: 550px;
        height: 900px;
    }
    .footer{
        display: none;
    }

}
@media only screen 
  and (min-device-width: 768px) 
  and (max-device-width: 1024px) 
  and (-webkit-min-device-pixel-ratio: 2) {
 .moniter-screen{
     width: 750px;
     height: 700px;
 }

}