
.about{
    position: relative;
    width: 40vw;
    height: auto;

    margin-top: 3vh;
    margin-right: -20vw;
    margin-left:1vw ;
    z-index: 1;
    font-family: 'Lucida Console', monospace;
    border-radius: 35px;
    box-shadow: 4px 4px 4px 4px rgba(0,0,0,.25);
    background-color: #343A40;
    border: solid 2px black;
 
    .blurb-a{
        margin-left:2%;
        margin-right: 0;
        margin-top: 1vh !important;
        width: 96%;
        text-align:  left;
        font-size: 17px;
        font-family: 'Lucida Sans', 'Lucida Sans Regular', 'Lucida Grande', 'Lucida Sans Unicode', Geneva, Verdana, sans-serif;
        color: #05ebe2;


        margin-top: 3vh; 
      } 
    
    }
    .me{
    position: relative;
    z-index: 3;
    height: 40%;
    width: 23%;
    margin-left: 2%;
    margin-top: 2%;
    margin-right: 2%;
    margin-bottom: -1%;
    border-radius: 23px ;
    float: left; 
   
  }   



@media  screen and (max-width: 1180px){
    .about{
        z-index: 1;
        margin-left: -30vw;
  
       
    }
 
}

@media only screen and (max-width: 970px){
  .blurb-a{
    z-index: 1;
    padding-top: 0vh;
    font-size: 15px !important;
  
  }
}

